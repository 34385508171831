import ig from "./../assets/ig.svg";
import pinterest from "./../assets/pinterest.svg";
import snapchat from "./../assets/snapchat.svg";
import tiktok from "./../assets/tiktok.svg";
import mail from "./../assets/mail.svg";
import "../styles/footer.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import React, { useEffect } from "react";

const Footer = ({ productTheme, fontColor = "inherit" }) => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const bodyElement = document.body;
    if (i18n.language === "ar") {
      bodyElement.classList.add("arabic-font");
    } else {
      bodyElement.classList.remove("arabic-font");
    }
  }, [i18n.language]);

  return (
    <footer className="footer" style={{ backgroundColor: productTheme }}>
      <div className="web">
        <div className="sign-up-footer">
          <div className="centered">
            <div className="sign-up-text" style={{ color: fontColor }}>
              {t("sign up for updates")}
            </div>
            <div className="row-button">
              <input
                type="text"
                placeholder={t("email address")}
                className="sign-up-input"
                style={{ color: fontColor }}
              />
              <button className="submit-button" style={{ color: fontColor }}>
                {t("Submit")}
              </button>
            </div>
          </div>
          <div className="name-container">
            <Link to={"/quiz"}>
              <div className="name-footer" style={{ color: fontColor }}>
                {t("Skin Quiz")}
              </div>
            </Link>
            <Link to={"/about-us"}>
              <div className="name-footer" style={{ color: fontColor }}>
                {t("Brand")}
              </div>
            </Link>
            <Link to={"/FAQs"}>
              <div className="name-footer" style={{ color: fontColor }}>
                {t("FAQs")}
              </div>
            </Link>
            <Link to={"/policies"}>
              <div className="name-footer" style={{ color: fontColor }}>
                {t("Policies")}
              </div>
            </Link>
            <a href="mailto:Hello@natindamiddleeast.com">
              <div className="name-footer" style={{ color: fontColor }}>
                {t("Customer Support Squad")}
              </div>
            </a>
            <Link to={"/privacy-and-terms"}>
              <div className="name-footer" style={{ color: fontColor }}>
                {t("Privacy & Terms")}
              </div>
            </Link>
          </div>
          <div className="name-footer" style={{ color: fontColor }}>
            {t("Connect with us")}
          </div>
          <div className="icons">
            <Link to={"https://www.instagram.com/natindamiddleeast/"}>
              <img src={ig} width={50} height={50} alt="ig" />
            </Link>
            <img src={pinterest} width={50} height={50} alt="pinterest" />
            <Link to={"https://snapchat.com/t/zXfw9HWc"}>
              <img src={snapchat} width={50} height={50} alt="snapchat" />
            </Link>
            <Link
              to={
                "https://www.tiktok.com/@natindamiddleeast?_t=8lKR7rho5wc&_r=1"
              }
            >
              <img src={tiktok} width={50} height={50} alt="tiktok" />
            </Link>
            <a href="mailto:Hello@natindamiddleeast.com">
              <img src={mail} width={50} height={50} alt="mail" />
            </a>
          </div>
          <div className="rights" style={{ color: fontColor }}>
            {t("Wojooh")}
          </div>
        </div>
      </div>
      <div className="mob">
        <div className="name-column">
          <Link to={"/quiz"}>
            <div className="name" style={{ color: fontColor }}>
              {t("Skin Quiz")}
            </div>
          </Link>
          <div className="line"></div>
          <Link to={"/about-us"}>
            <div className="name" style={{ color: fontColor }}>
              {t("Brand")}
            </div>
          </Link>
          <div className="line"></div>
          <Link to={"/FAQs"}>
            <div className="name" style={{ color: fontColor }}>
              {t("FAQs")}
            </div>
          </Link>
          <div className="line"></div>
          <Link to={"/policies"}>
            <div className="name" style={{ color: fontColor }}>
              {t("Policies")}
            </div>
          </Link>
          <div className="line"></div>
          <a href="mailto:Hello@natindamiddleeast.com">
            <div className="name" style={{ color: fontColor }}>
              {t("Customer Support Squad")}
            </div>
          </a>
          <div className="line"></div>
          <Link to={"/privacy-and-terms"}>
            <div className="name" style={{ color: fontColor }}>
              {t("Privacy & Terms")}
            </div>
          </Link>
          <div className="line"></div>
          <div className="name-copy" style={{ color: fontColor }}>
            {t("Wojooh")}
          </div>
          <div className="center" style={{ color: fontColor }}>
            {t("Connect with us")}
          </div>
          <div className="icons">
            <Link to={"https://www.instagram.com/natindamiddleeast/"}>
              <img src={ig} width={15} height={15} alt="ig" />
            </Link>
            <img src={pinterest} width={15} height={15} alt="pinterest" />
            <Link to={"https://snapchat.com/t/zXfw9HWc"}>
              <img src={snapchat} width={15} height={15} alt="snapchat" />
            </Link>
            <Link
              to={
                "https://www.tiktok.com/@natindamiddleeast?_t=8lKR7rho5wc&_r=1"
              }
            >
              <img src={tiktok} width={15} height={15} alt="tiktok" />
            </Link>
            <a href="mailto:Hello@natindamiddleeast.com">
              <img src={mail} width={15} height={15} alt="mail" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
