import "../../styles/sign-up.css";
import "../../fonts/fonts.css";
import Footer from "components/Footer";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useState } from "react";
import Input from "./Input";
import Button from "./Button";
import validateEmail from "./utils";
import Checkbox from "@mui/material/Checkbox";
import { SyncLoader } from "react-spinners";
import DatePickerComponent from "components/DatePickerComponent";
import { useTranslation } from "react-i18next";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { isValidPhoneNumber } from "libphonenumber-js";

import ErrorIcon from "./Error";
import Valid from "./Valid";

export default function SignUpForm() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const [phone, setPhone] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [isPhoneTouched, setIsPhoneTouched] = useState(false);

  const [firstName, setFirstName] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });

  const [lastName, setLastName] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });

  const [email, setEmail] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });

  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [receiveEmailsChecked, setReceiveEmailsChecked] = useState(false);

  const [password, setPassword] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });

  const [confirmPassword, setConfirmPassword] = useState({
    value: "",
    isTouched: false,
    isValid: false,
  });

  const [howYouKnowOption, setHowYouKnowOption] = useState(null);

  function handleFirstNameInput(e) {
    let firstName = e.target.value;

    // Capitalize the first letter
    firstName = firstName.charAt(0).toUpperCase() + firstName.slice(1);

    const isValid = /^[A-Za-z]+$/.test(firstName) && firstName.length > 3;

    setFirstName({
      isTouched: true,
      value: firstName,
      isValid: isValid,
    });
  }

  function handleLastNameInput(e) {
    let lastName = e.target.value;

    // Capitalize the first letter
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1);

    const isValid = /^[A-Za-z]+$/.test(lastName) && lastName.length > 3;

    setLastName({
      isTouched: true,
      value: lastName,
      isValid: isValid,
    });
  }

  function handleEmailInput(e) {
    setEmail({
      isTouched: true,
      value: e.target.value,
      isValid: validateEmail(e.target.value),
    });
  }

  const handlePhoneChange = (phone) => {
    // Convert Arabic numerals to Western numerals
    const convertToWesternNumbers = (str) => {
      const arabicToWestern = {
        "٠": "0",
        "١": "1",
        "٢": "2",
        "٣": "3",
        "٤": "4",
        "٥": "5",
        "٦": "6",
        "٧": "7",
        "٨": "8",
        "٩": "9",
      };
      return str.replace(/[٠١٢٣٤٥٦٧٨٩]/g, (match) => arabicToWestern[match]);
    };

    const value = phone;
    const westernValue = convertToWesternNumbers(value);

    setPhone(westernValue);
    setIsPhoneValid(isValidPhoneNumber(phone));
  };
  const handlePhoneFocus = () => {
    setIsPhoneTouched(true);
  };

  function handlePasswordInput(e) {
    const password = e.target.value;
    const isValid =
      password.length >= 8 &&
      /[A-Z]/.test(password) && // At least one uppercase letter
      /[a-z]/.test(password) && // At least one lowercase letter
      /[0-9]/.test(password) && // At least one number
      /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/.test(password); // At least one special character

    setPassword({
      isTouched: true,
      value: password,
      isValid: isValid,
    });

    // Update confirm password validity
    setConfirmPassword((prevState) => ({
      ...prevState,
      isValid: isValid && prevState.value === password,
    }));
  }

  function handleConfirmPasswordInput(e) {
    setConfirmPassword({
      isTouched: true,
      value: e.target.value,
      isValid: e.target.value === password.value,
    });
  }
  function handleReceiveEmailsChange() {
    setReceiveEmailsChecked(!receiveEmailsChecked);
  }

  // Function to handle selection of how user knows about the site
  function handleHowYouKnowOptionChange(option) {
    if (option === howYouKnowOption) {
      // If the same option is clicked again, deselect it
      setHowYouKnowOption(null);
    } else {
      // Otherwise, set the clicked option
      setHowYouKnowOption(option);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    setLoading(true);

    const formData = {
      first_name: firstName.value,
      last_name: lastName.value,
      email: email.value,
      birthday: `${selectedYear.value}-${String(selectedMonth.value).padStart(
        2,
        "0"
      )}-${String(selectedDay.value).padStart(2, "0")}`,
      phone: phone,
      about_us: howYouKnowOption,
      password: password.value,
      accept_promotion: receiveEmailsChecked ? 1 : 0,
    };
    const response = await fetch(
      "https://dashboard.natindamiddleeast.com/api/createUser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    const responseData = await response.json();

    if (response.ok) {
      if (location.state && location.state.fromQuiz) {
        navigate("/verification", {
          state: {
            phoneNumber: phone,
            fromQuiz: true,
            result: location.state.result,
          },
        });
      } else {
        navigate("/verification", {
          state: { phoneNumber: phone },
        });
      }
    } else {
      setLoading(false);
      setError(true);
      setErrorMessage(responseData.errors[0]);
    }
  }

  const formIsValid =
    firstName.isValid &&
    lastName.isValid &&
    email.isValid &&
    isValidPhoneNumber(phone) &&
    selectedYear !== null &&
    selectedMonth !== null &&
    selectedDay !== null &&
    password.isValid &&
    confirmPassword.isValid &&
    howYouKnowOption !== null;

  return (
    <div>
      {loading ? (
        <div className="loader-overlay">
          <SyncLoader className="PuffLoader" color="rgb(252, 219, 204)" />
        </div>
      ) : (
        <div className="ooo">
          <div className="sign-up">
            <div> {t("Create an account")}</div>
            {error && (
              <div className="error-message">
                {/* This Phone Number Is Already Used */}
                {errorMessage}
              </div>
            )}

            <div className="sign-up-info">
              <Input
                name="firstName"
                type="text"
                onChange={handleFirstNameInput}
                isValid={firstName.isValid}
                isTouched={firstName.isTouched}
                placeholder={t("First Name")}
                value={firstName.value}
                errorMsg={t("Enter a valid first name")}
              />
              <Input
                name="lastName"
                type="text"
                onChange={handleLastNameInput}
                isValid={lastName.isValid}
                isTouched={lastName.isTouched}
                placeholder={t("Last Name")}
                value={lastName.value}
                errorMsg={t("Enter a valid last name")}
              />
              <Input
                name="email"
                type="text"
                onChange={handleEmailInput}
                isValid={email.isValid}
                isTouched={email.isTouched}
                placeholder={t("Email")}
                value={email.value}
                errorMsg={t("Enter a valid email")}
              />

              <div>
                <div className="phone-input-wrapper">
                  <PhoneInput
                    defaultCountry="sa"
                    value={phone}
                    onChange={handlePhoneChange}
                    onFocus={handlePhoneFocus}
                    className={`phone-input ${
                      !isPhoneValid ? "error" : "valid"
                    }`}
                  />
                  <div className="validation-icon">
                    {isPhoneTouched &&
                      (!isPhoneValid ? <ErrorIcon /> : <Valid />)}
                  </div>
                </div>
                {!isPhoneValid && (
                  <p className="input-error">
                    {isPhoneTouched && t("valid_phone_error")}
                  </p>
                )}
              </div>

              <DatePickerComponent
                selectedYear={selectedYear}
                selectedMonth={selectedMonth}
                selectedDay={selectedDay}
                setSelectedYear={setSelectedYear}
                setSelectedMonth={setSelectedMonth}
                setSelectedDay={setSelectedDay}
              />

              <Input
                name="password"
                type="password"
                onChange={handlePasswordInput}
                isValid={password.isValid}
                isTouched={password.isTouched}
                placeholder={t("Password")}
                value={password.value}
                errorMsg={t("valid_password")}
              />

              <div className="password-instructions">
                {t("password-instructions")}
              </div>
              <Input
                name="confirmPassword"
                type="password"
                onChange={handleConfirmPasswordInput}
                isValid={confirmPassword.isValid}
                isTouched={confirmPassword.isTouched}
                placeholder={t("Confirm Password")}
                value={confirmPassword.value}
                errorMsg={t("Passwords do not match")}
              />

              {/* How you know about us question and options */}
              <div className="how-you-know-container">
                <div className="how-you-know-question">
                  {t("how_did_you_hear_about_us")}
                </div>
                <div className="how-you-know-options">
                  <div>
                    <Checkbox
                      checked={howYouKnowOption === "instagram"}
                      onChange={() => handleHowYouKnowOptionChange("instagram")}
                      sx={{
                        color: "rgba(252, 219, 204, 1)",
                        "&.Mui-checked": {
                          color: "rgba(252, 219, 204, 1)",
                        },
                      }}
                    />
                    {t("instagram")}
                  </div>

                  <div>
                    <Checkbox
                      checked={howYouKnowOption === "Tiktok"}
                      onChange={() => handleHowYouKnowOptionChange("Tiktok")}
                      sx={{
                        color: "rgba(252, 219, 204, 1)",
                        "&.Mui-checked": {
                          color: "rgba(252, 219, 204, 1)",
                        },
                      }}
                    />
                    {t("tiktok")}
                  </div>

                  <div>
                    <Checkbox
                      checked={howYouKnowOption === "Friend Recommendation"}
                      onChange={() =>
                        handleHowYouKnowOptionChange("Friend Recommendation")
                      }
                      sx={{
                        color: "rgba(252, 219, 204, 1)",
                        "&.Mui-checked": {
                          color: "rgba(252, 219, 204, 1)",
                        },
                      }}
                    />
                    {t("friend_recommendation")}
                  </div>

                  <div>
                    <Checkbox
                      checked={howYouKnowOption === "Influencer Recommendation"}
                      onChange={() =>
                        handleHowYouKnowOptionChange(
                          "Influencer Recommendation"
                        )
                      }
                      sx={{
                        color: "rgba(252, 219, 204, 1)",
                        "&.Mui-checked": {
                          color: "rgba(252, 219, 204, 1)",
                        },
                      }}
                    />
                    {t("influencer_recommendation")}
                  </div>

                  <div>
                    <Checkbox
                      checked={howYouKnowOption === "PR Magazine"}
                      onChange={() =>
                        handleHowYouKnowOptionChange("PR Magazine")
                      }
                      sx={{
                        color: "rgba(252, 219, 204, 1)",
                        "&.Mui-checked": {
                          color: "rgba(252, 219, 204, 1)",
                        },
                      }}
                    />
                    {t("pr_magazine")}
                  </div>
                </div>
              </div>

              {/* Checkbox for receiving emails */}
              <div className="receive-emails">
                <Checkbox
                  checked={receiveEmailsChecked}
                  onChange={handleReceiveEmailsChange}
                  sx={{
                    color: "rgba(252, 219, 204, 1)",
                    "&.Mui-checked": {
                      color: "rgba(252, 219, 204, 1)",
                    },
                  }}
                />
                {t("receive-emails")}
              </div>

              <Button
                className="submit"
                text={t("Create an account")}
                onClick={handleSubmit}
                disabled={!formIsValid}
              />
            </div>
          </div>

          <Footer />
        </div>
      )}
    </div>
  );
}
