import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import natindaLogo from "../assets/Natinda_Logo.svg";
import searchIcon from "../assets/search-magnifying-glass.svg";
import cartIcon from "../assets/cart_icon.svg";
import hamburgerIcon from "../assets/Hamburger-icon.svg";
import blackline from "../assets/Black-line.svg";
import ProfileIcon from "../assets/profile.svg";
import "../styles/Navbar.css";
import "../fonts/fonts.css";
import Searchbar from "./Searchbar";
import CloseButton from "react-bootstrap/CloseButton";
import User from "User";
import AuthContext from "AuthContext";
import { useContext } from "react";
import CartContext from "CartContext";
import { MdKeyboardArrowRight } from "react-icons/md";
import { useTranslation } from "react-i18next";
import translateIcon from "../assets/iconTranslate.svg";

export default function Navbar({ productTheme, fontColor = "inherit" }) {
  const [menuOpen, setMenuOpen] = useState(false);
  const [openSearchBar, setOpenSearchBar] = useState(false);
  const [showSubMenu, setShowSubMenu] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const searchQuery = "";
  const [hintList] = useState([]);
  const [data] = useState([
    {
      id: 6,
      name_en: "Pour Le Cellule Whitening Cream  -70g",
      name_ar: "اتيكوس بور سيلليو وايتينينج كريم - ٧٠ج",
    },
    {
      id: 7,
      name_en: "Multi-Stick Moisture Care -9g ",
      name_ar: "مالتي استيك مويستشر كير - ٩ج",
    },
    {
      id: 8,
      name_en: "Calming Sherbet Cleansing Balm -100ml",
      name_ar: "كالمينج شيربيت كلينسينج بالم -  ١٠٠مل",
    },
    {
      id: 9,
      name_en: "Multi-Stick UV Care -9g",
      name_ar: "مالتي استيك اكوا يوفي كير - ٩ج",
    },
    {
      id: 10,
      name_en: " Calming Refresh Cleansing Foam  -150ml",
      name_ar: "كالمينج ريفريشينج كلينسينج فوم - ١٥٠مل",
    },
    {
      id: 11,
      name_en: "Atticos – 24k Gold Ampoule -50ml",
      name_ar: "اتيكوس 24K جولد امبول",
    },
  ]);

  const { isAuthenticated, handleLogout } = useContext(AuthContext);
  const { noProducts } = useContext(CartContext);

  const profileDropdownRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const storedUser = localStorage.getItem("user");
        if (storedUser) {
          const currentUser = new User(JSON.parse(storedUser));
        }
      } catch (error) {
      } finally {
      }
    };

    fetchData();

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [noProducts]);

  const { t, i18n } = useTranslation();
  const isArabic = i18n.language === "ar";

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  // Function to toggle language on click
  const toggleLanguage = () => {
    if (isArabic) {
      changeLanguage("en"); // Change to English if currently Arabic
    } else {
      changeLanguage("ar"); // Change to Arabic if currently English
    }
  };

  const handleClickOutside = (event) => {
    if (
      profileDropdownRef.current &&
      !profileDropdownRef.current.contains(event.target)
    ) {
      setProfileDropdownOpen(false);
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
    setShowSubMenu(false);
  };
  const closHamburgerMenu = () => {
    if (menuOpen) {
      setMenuOpen(!menuOpen);
    }
  };
  const toggleSubMenu = () => {
    setShowSubMenu(!showSubMenu);
  };

  const toggleSearchBar = () => {
    setMenuOpen(false);
    setOpenSearchBar(!openSearchBar);
  };

  const toggleProfileDropdown = () => {
    setProfileDropdownOpen(!profileDropdownOpen);
  };

  function handleHomeLink() {
    setMenuOpen(false);
  }

  return (
    <>
      <div className="pc-navbar-container">
        <div className="pc-navbar-announcement-stripe">
          <div className="announcement-text-container">
            <p className={`announcement-text ${isArabic ? "arabic" : ""}`}>
              {t("announcement-bar-text")}
            </p>
          </div>
        </div>
        <div className="pc-navbar-main">
          <Link to={"/"} className="pc-natinda-logo">
            <img
              src={natindaLogo}
              alt="Natinda Logo"
              className="pc-natinda-image"
            />
          </Link>
          <div className="pc-search-bar">
            <Searchbar defaultValue={searchQuery} hintList={hintList} />
          </div>
          <div className="pc-navbar-right-align">
            {isAuthenticated ? (
              <>
                <div
                  className={`translateContainer ${isArabic ? "arabic" : ""}`}
                  onClick={toggleLanguage}
                >
                  {isArabic ? <div>English</div> : <div>العربية</div>}
                  <img src={translateIcon} alt="Translate Icon" />
                </div>
                <div ref={profileDropdownRef} className="profile-dropdown">
                  <img
                    src={ProfileIcon}
                    width={30}
                    height={30}
                    alt="profileLogo"
                    onClick={toggleProfileDropdown}
                  />
                  {profileDropdownOpen && (
                    <div className="profile-dropdown-menu">
                      <Link to="/profile" className="dropdown-item">
                        {t("Profile")}
                      </Link>
                      <div className="divider"></div>
                      <button className="dropdown-item" onClick={handleLogout}>
                        {t("log_out")}
                      </button>
                    </div>
                  )}
                </div>
                <Link to="/cart" className="cart-click">
                  {noProducts > 0 ? (
                    <div className="cart-container">
                      <div className="cart-link">
                        <div className="cart-icon-container">
                          <img
                            src={cartIcon}
                            width={30}
                            height={30}
                            alt="cartLogo"
                          />
                          <span className="cart-count">{noProducts}</span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <img src={cartIcon} width={30} height={30} alt="cartLogo" />
                  )}
                </Link>
              </>
            ) : (
              <>
                <div
                  className={`translateContainer ${isArabic ? "arabic" : ""}`}
                  onClick={toggleLanguage}
                >
                  {isArabic ? <div>English</div> : <div>العربية</div>}
                  <img src={translateIcon} alt="Translate Icon" />
                </div>
                <Link className={"pc-navbar-sign-text"} to={"login"}>
                  <img
                    src={ProfileIcon}
                    width={30}
                    height={30}
                    alt="profileLogo"
                  />
                </Link>
                <Link to="/cart" className="cart-click">
                  {noProducts > 0 ? (
                    <div className="cart-icon-container">
                      <img
                        src={cartIcon}
                        width={30}
                        height={30}
                        alt="cartLogo"
                      />
                      <span className="cart-count">{noProducts}</span>
                    </div>
                  ) : (
                    <img src={cartIcon} width={30} height={30} alt="cartLogo" />
                  )}
                </Link>
              </>
            )}
          </div>
        </div>
        <div
          className="pc-navbar-menu"
          style={{ backgroundColor: `${productTheme}` }}
        >
          <Link
            to={"/products"}
            className={`pc-navbar-menu-text ${isArabic ? "arabic" : ""}`}
          >
            {t("pc-navbar-menu-shopAll-text")}
          </Link>
          <Link
            to={"/gift-sets"}
            className={`pc-navbar-menu-text ${isArabic ? "arabic" : ""}`}
          >
            {t("pc-navbar-menu-gifts-text")}
          </Link>
          <Link
            to={"/skinJournal"}
            className={`pc-navbar-menu-text ${isArabic ? "arabic" : ""}`}
          >
            {t("pc-navbar-menu-skinJournal-text")}
          </Link>
          <Link
            to={"/quiz"}
            className={`pc-navbar-menu-text ${isArabic ? "arabic" : ""}`}
          >
            {t("pc-navbar-menu-skinQuiz-text")}
          </Link>
          <Link
            to={"/accessories"}
            className={`pc-navbar-menu-text ${isArabic ? "arabic" : ""}`}
          >
            {t("pc-navbar-menu-accessories-text")}
          </Link>
          <Link
            to={"/rewards"}
            className={`pc-navbar-menu-text ${isArabic ? "arabic" : ""}`}
          >
            {t("pc-navbar-menu-rewards-text")}
          </Link>
        </div>
      </div>

      <div className="mobile-navbar-container">
        {!menuOpen && (
          <div className="pc-navbar-announcement-stripe">
            <div className="announcement-text-container">
              <p className="announcement-text">{t("announcement-bar-text")}</p>
            </div>
          </div>
        )}
        <div
          className="mobile-navbar-main"
          style={{ backgroundColor: `${productTheme}` }}
        >
          {!openSearchBar ? (
            <>
              <div className="left-align">
                {menuOpen ? (
                  <IoCloseOutline
                    onClick={toggleMenu}
                    className="close-button"
                  />
                ) : (
                  <img
                    src={hamburgerIcon}
                    alt="pc-hamburger-icon"
                    className="mobile-hamburger-icon"
                    onClick={toggleMenu}
                  />
                )}
              </div>
              <Link
                to={"/"}
                className="mobile-natinda-logo"
                onClick={handleHomeLink}
              >
                <img
                  src={natindaLogo}
                  alt="Natinda Logo"
                  className="mobile-natinda-image"
                />
              </Link>
              <div className="right-align">
                <img
                  src={searchIcon}
                  alt="search Icon"
                  onClick={toggleSearchBar}
                />

                <Link
                  className="cart-click"
                  to="/cart"
                  onClick={closHamburgerMenu}
                >
                  {noProducts > 0 ? (
                    <div className="cart-container-nav-bar">
                      <div className="cart-icon-container-nav-bar">
                        <img src={cartIcon} alt="cartLogo" />
                        <span className="cart-count-nav-bar">{noProducts}</span>
                      </div>
                    </div>
                  ) : (
                    <img src={cartIcon} alt="cartLogo" />
                  )}
                </Link>
              </div>
            </>
          ) : (
            <div className="search-and-close">
              <Searchbar defaultValue={searchQuery} hintList={hintList} />
              <CloseButton onClick={toggleSearchBar} />
            </div>
          )}
        </div>
      </div>

      <div className={`mobile-hamburger-menu ${menuOpen ? "menu-open" : ""}`}>
        <div className="mobile-hamburger-menu-text-container">
          {!showSubMenu && (
            <>
              <Link
                to={"/products"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-shop-all")}
              </Link>
              <div
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
                onClick={toggleSubMenu}
              >
                {t("Skin_Care")}
                <MdKeyboardArrowRight
                  height={25}
                  style={{
                    transform: isArabic ? "rotate(180deg)" : "none",
                  }}
                />
              </div>
            </>
          )}
          {showSubMenu && (
            <>
              {data.map((product) => (
                <Link
                  key={product.id}
                  to={`/perviewproduct/${product.id}`}
                  onClick={toggleMenu}
                  className={`mobile-hamburger-menu-text ${
                    isArabic ? "arabic" : ""
                  }`}
                >
                  {isArabic ? product.name_ar : product.name_en}
                </Link>
              ))}
            </>
          )}
          {!showSubMenu && (
            <>
              <Link
                to={"/gift-sets"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-Gifts-Sets")}
              </Link>
              <Link
                to={"/skinJournal"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-Skin-Journal")}
              </Link>
              <Link
                to={"/quiz"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-Skin-Quiz")}
              </Link>
              <Link
                to={"/accessories"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-Accessories")}
              </Link>
              <img
                src={blackline}
                className="mobile-hamburger-menu-black-line"
                alt="blackLine"
              />
              <Link
                to={"/rewards"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-VIP-Squad")}
              </Link>
              <Link
                to={"/about-us"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-About-Us")}
              </Link>
              {isAuthenticated ? (
                <Link
                  to={"/profile"}
                  onClick={toggleMenu}
                  className={`mobile-hamburger-menu-text ${
                    isArabic ? "arabic" : ""
                  }`}
                >
                  {t("mobile-hamburger-menu-Profile")}
                </Link>
              ) : (
                <Link
                  to={"/login"}
                  onClick={toggleMenu}
                  className={`mobile-hamburger-menu-text ${
                    isArabic ? "arabic" : ""
                  }`}
                >
                  {t("mobile-hamburger-menu-SignUp/In")}
                </Link>
              )}
              <Link
                to={"/customerSupportSquad"}
                onClick={toggleMenu}
                className={`mobile-hamburger-menu-text ${
                  isArabic ? "arabic" : ""
                }`}
              >
                {t("mobile-hamburger-menu-Customer Support Squad")}
              </Link>

              <div
                className={`translateContainer ${isArabic ? "arabic" : ""}`}
                onClick={toggleLanguage}
              >
                {isArabic ? <div>English</div> : <div>العربية</div>}
                <img src={translateIcon} alt="Translate Icon" />
              </div>

              <img
                src={blackline}
                className="mobile-hamburger-menu-black-line"
                alt="blackLine"
              />
            </>
          )}
        </div>
      </div>
    </>
  );
}
